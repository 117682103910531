#root
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-around.w-100.p-3.border-bottom
  > div.searchStack.css-1jruifh-MuiStack-root
  > div
  > div
  > div {
  border-radius: 46px !important;
}

#root
  > div
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-evenly.align-items-center.w-100.border-bottom
  > div.col-5.css-ikzlcq
  > div
  > div
  > div {
  padding: 0px 10px 0px 0px !important;
}

#root
  > div
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-evenly.align-items-center.w-100.border-bottom
  > div.col-5.css-1p5q5e5-MuiStack-root
  > div
  > div
  > div {
  padding: 0px 10px 0px 0px !important;
}
