/* html{
  background-color: rgb(255, 178, 127) !important;
} */
body {
  font-family: "Tajawal", sans-serif !important;
}
a {
  text-decoration: none !important;
  color: black !important;
}
/* 
html:lang(en) {
  direction: rtl !important;
} */
#root
  > div.container.my-5
  > div
  > div.col-lg-4.col-md-12
  > div
  > div.d-flex.justify-content-center.mt-1.w-100.border-top.p-3
  > div
  > button:nth-child(3)
  > svg {
  border-radius: 70% !important;
  margin-right: 10px !important;
}
#root
  > div.container.my-5
  > div
  > div.col-lg-4.col-md-12
  > div
  > div.d-flex.justify-content-center.mt-1.w-100.border-top.p-3
  > div
  > button:nth-child(2)
  > svg {
  border-radius: 70% !important;
  margin-right: 10px !important;
}
#root
  > div.container.my-5
  > div
  > div.col-lg-4.col-md-12
  > div
  > div.d-flex.justify-content-center.mt-1.w-100.border-top.p-3
  > div
  > button:nth-child(1)
  > svg {
  border-radius: 70% !important;
  margin-right: 10px !important;
}
.text-primary {
  color: #294595 !important ;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f9fa;
  box-shadow: inset 0 0 3px rgb(173, 172, 172);
  /* border-radius: 50px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #263997;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffba35;
}

html{
  scroll-padding-top: 125px;
}
