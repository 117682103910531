html:lang(ar) {
  direction: ltr !important;
}

/* html:lang(en) {
  direction: rtl !important;
} */
li {
  list-style: none;
}

.border-button {
  border: solid 1px #08223c !important;
  font-size: 3rem !important;
}

.navigation {
  position: relative;
  display: inline-block;
}

.navigation-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 800px !important;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation a {
  color: black;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation a:hover {
  background-color: #263997;
  color: white !important;
  border-radius: 4px;
}
.keyboardArrow {
  rotate: -90deg;
  transition: ease all 0.2s;
}

.navigation:hover .keyboardArrow {
  rotate: 0deg;
  transition: ease all 0.2s;
}

.navigation:hover .navigation-content {
  display: block;
}

.image--logo {
  width: 10%;
}

.logo--image {
  cursor: pointer !important;
}

/* .searchbar-navbar {
  width: 80% !important;
} */

/* #root > div > div.bg-light.nav--bar {
  max-height: 22vh !important;
} */

#root
  > div
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-around.align-items-end.w-100.border-bottom
  > div.searchStack.css-1jruifh-MuiStack-root {
  margin-bottom: 1rem !important;
}

.nav--bar {
  z-index: 99999;
  position: sticky !important;
  top: 0 !important;
}

.language-button {
  cursor: pointer;
}

.profile-btn {
  font-size: 30px !important;
  color: black !important;
}
.username-button {
  margin-bottom: 1px !important;
  color: #08223c !important;
  font-size: 0.9rem;
}

#root
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-around.w-100.border-bottom
  > div.searchStack.css-1jruifh-MuiStack-root
  > div
  > div
  > div {
  border-radius: 29px !important;
}

.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 30px !important;
}

.shoppingCart {
  font-size: calc(1.275rem + 0.3vw) !important;
}
#root
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-around.w-100.border-bottom
  > div.searchStack.css-1cvx1hr
  > div
  > div
  > div {
  border-radius: 29px !important;
  height: 7vh !important;
}

.MuiInputBase-root {
  border-radius: 29px !important;
  /* height: 5vh !important; */
}

#root
  > div
  > div.bg-light.nav--bar
  > div.d-flex.justify-content-evenly.align-items-center.w-100.border-bottom
  > div.col-5.css-ikzlcq
  > div
  > div
  > div {
  padding: 0px 10px 0px 0px !important;
}

@media only screen and (min-width: 601px) {
  .logo--image {
    width: 99px !important;
    height: auto;
    margin: 2px 3.5rem 2px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .logo--image {
    max-height: 49px !important;
    width: auto;
    margin: 4px 3px 4px 0px;
  }
  .navigation-content {
    display: none !important;
  }
  .icon-none-sm {
    display: none !important;
  }

  .girl_position {
    object-position: -15px !important;
  }

  #root
    > div.bg-light.nav--bar
    > div:nth-child(2)
    > ul
    > li:nth-child(1)
    > div
    > a {
    padding: 0px !important;
  }

  .hover-underline-animation {
    padding: 0px !important;
  }

  #root
    > div.bg-light.nav--bar
    > div:nth-child(2)
    > ul
    > li.px-2.my-1
    > a
    > button {
    padding: 0px !important;
  }

  .profile-btn {
    font-size: 3rem !important;
    margin: 0 2px 0 2px;
    border: #184799 !important;
  }

  #root > div.d-flex {
    flex-direction: column !important;
  }

  #root
    > div:nth-child(2)
    > div
    > div.col-md-6.d-flex.flex-column.justify-content-center
    > h1:nth-child(1) {
    font-size: 2rem !important;
  }

  #root
    > div:nth-child(2)
    > div
    > div.col-md-6.d-flex.flex-column.justify-content-center
    > h1:nth-child(2) {
    font-size: 2rem !important;
  }

  #root
    > div:nth-child(2)
    > div
    > div.col-md-6.d-flex.flex-column.justify-content-center {
    align-items: center !important;
  }

  #root > div:nth-child(2) > div > div.col-md-6.position-relative > img {
    display: none !important;
  }

  #root
    > div:nth-child(3)
    > div
    > div.col-md-6.d-flex.flex-column.justify-content-center
    > h1:nth-child(1) {
    font-size: 2rem !important;
    text-align: center !important;
  }

  #root
    > div:nth-child(3)
    > div
    > div.col-md-6.d-flex.flex-column.justify-content-center
    > h1.mb-3 {
    font-size: 2rem !important;
    text-align: center !important;
  }

  #root
    > div:nth-child(3)
    > div
    > div.col-md-6.d-flex.flex-column.justify-content-center
    > p {
    width: 100% !important;
  }

  #root > div:nth-child(4) > h1 {
    font-size: 2rem !important;
  }

  #root
    > div.container
    > div
    > div.col-md-5.my-5.d-flex.align-items-center
    > div
    > h1 {
    font-size: 2rem !important;
  }

  #root
    > div.container
    > div
    > div.col-md-5.my-5.d-flex.align-items-center
    > div
    > h6 {
    font-size: 1.5rem !important;
  }

  #root
    > div.container
    > div
    > div.col-md-7.position-relative
    > img:nth-child(2) {
    width: 90vw !important;
  }

  #root
    > div.position-relative.w-75.m-auto.my-5
    > div
    > form
    > div.d-flex.justify-content-center
    > button,
  .btn--joinUs {
    width: 100% !important;
    font-size: 14px !important;
  }

  .swiper--margin {
    margin-left: 4rem !important;
  }

  .navigation {
    font-size: 10px !important;
  }
  .descover-new {
    font-size: 10px !important;
    margin-top: -6px !important;
  }
}

/* .btn--signin {
  border-radius: 150px !important;
  border: solid 1px #184799 !important;
  width: 10% !important;
  height: 6vh !important;
  margin-top: 15px !important;
  background-color: #184799 !important;
} */
.btn--signin {
  /* height: 6vh !important; */
  background-color: #184799 !important;
  border-radius: 150px !important;
  border: solid 1px #184799 !important;
  width: fit-content !important;
  font-size: 1rem !important;
}
@media only screen and (max-width: 680px) {
  .shoppingCart {
    font-size: 0.8rem !important;
    margin-left: 0.2rem !important;
  }
  .btn--signin {
    font-size: 0.8rem !important;
    min-width: 23vw !important;
  }
  .account-circle {
    font-size: 0.6rem !important;
  }
  .profile-btn {
    width: 10vw !important;
  }
}
.res-h1 {
  font-size: 2rem !important;
}

.beta-version-header {
  background-color: #184799;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beta-version-animation {
  width: fit-content;
  margin: 0px;
  padding: 2px 0px 0px 0px;
}
/* 
@media only screen and (max-width: 768px) {
  .beta-version-animation {
    animation: movingRightAndLeft linear 10s alternate infinite;
    position: absolute;
  }
}

@media only screen and (min-width: 769px) {
  .beta-version-animation {
    animation: movingRightAndLeft linear 20s alternate infinite;
    position: absolute;
  }
}

@keyframes movingRightAndLeft {
  from {
    right: 0px;
  }
  to {
    right: calc(100% - 146px);
  }
} */
